<template>
  <v-container fluid>
    <v-card elevation="4" class="mt-0">
      <v-tabs
        v-model="tab"
        @change="tabChanged(tab)"
        background-color="#5E2FBE"
        dark
        disabled
      >
        <v-tab :disabled="loading" v-for="item in items" :key="item.tab">
          {{ item.tab }}
        </v-tab>
        <v-btn
          outlined
          small
          class="my-auto ml-auto mr-2 rounded-0"
          v-if="$admin.can('inbound-export')"
          @click="getCSV(filters)"
          :loading="is_loading_csv"
          :disabled="is_loading_csv"
        >
          {{ $t("export_csv") }}
        </v-btn>
        <import-csv v-if="$admin.can('inbound-import')"></import-csv>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in items" :key="item.tab">
          <incoming-tab
            :filters="filters"
            :isActive="items[tab]?.content == 'incoming'"
            v-if="item.content == 'incoming'"
          ></incoming-tab>
          <received-tab
            :filters="filters"
            :isActive="items[tab]?.content == 'received'"
            v-if="item.content == 'received'"
          ></received-tab>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
export default {
  components: {
    IncomingTab: () => import("./components/tabs/IncomingTab.vue"),
    ReceivedTab: () => import("./components/tabs/ReceivedTab.vue"),

    ImportCsv: () => import("./dialogs/ImportInboundCSV.vue"),
  },

  computed: {
    filters: {
      get() {
        return this.$store.state.inboundProducts.filters;
      },
      set(value) {
        this.$store.commit("inboundProducts/SET_FILTERS", value);
      },
    },
  },
  methods: {
    async tabChanged(index) {
      try {
        this.loading = true;
        this.filters.inbound_status_name = this?.items[index]?.status;
        this.$store.commit("inboundProducts/CLEAN_LIST");
        await this.$store.dispatch("inboundProducts/list", this.filters);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },

    async getCSV(filters) {
      this.is_loading_csv = true;
      await this.$store
        .dispatch("inboundProducts/downloadCSV", filters)
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "inbound_products.csv");
          document.body.appendChild(link);
          // window.open(link);
          link.click();
          this.is_loading_csv = false;
        })
        .catch((error) => {
          this.is_loading_csv = false;
          this.$store.dispatch("alerts/error", error.response?.statusText);
        });
    },
  },

  async created() {
    this.filters.inbound_status_name = this?.items[this.tab]?.status ?? "open";
  },

  data() {
    return {
      is_loading_csv: false,
      tab: null,
      loading: false,
      items: [
        {
          tab: this.$t("incoming"),
          content: "incoming",
          status: "open",
        },
        {
          tab: this.$t("received"),
          content: "received",
          status: "history",
        },
      ],
    };
  },
};
</script>
